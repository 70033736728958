.modal-description {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 380px;
    /* font-family: Roboto !important; */
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 38px;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #979797;
}

.modal-description p {
     margin: 0 auto;
}

.modal-description button {
    background-color: #21B4CA !important;
    color: white !important;
    font-size: 18px !important;
    margin-top: 5% !important;
}
.client-reset-modal{
    padding:1fr !important;
    margin: 0 !important;
}

.modal-btn-save{
    margin-top:20px !important;
}