.right-side {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  margin: 1.6rem;
  flex: 1;
  /*width: 100%;*/
  min-height: 100vh;
  /*padding-bottom: 200px;*/

}
a {
  justify-self: flex-start;
  align-self: flex-start;
}
.page-padding {
  flex: 1;
  display: flex;
  /*align-items: flex-start;*/
  flex-direction: column;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  /*line-height: 29px;*/
  color: #2c2c2c;
}
