.load-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.container.ar .load-div {
    flex-direction: column-reverse;
}

.load-div-dark {
    background-color: #121212;
}

.load-div img {
    width: 125px;
    height: 90px;
}

.load-div h4 {
    color: #21B4CA;
    font-family: Montserrat, serif;
    font-style: normal;
    margin: 0 4px 0 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
}

.animated-bar-container {
    width: 250px;
    height: 2px;
    background-color: #8c97b255;
}

.animated-bar {
    background-color: #21B4CA;
    height: 2px;
    width: 50px;
}
.animated-bar{
    animation: translation 2s infinite ;
}

.container-ar .animated-bar{
    animation: translationResverse 2s infinite ;
}

@keyframes translation {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(400%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes translationResverse {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-400%);
    }
    100% {
        transform: translateX(0);
    }
}
