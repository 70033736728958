.tab-custom {
    display: flex;
    flex: 1;
    /*width: 100%;*/
    overflow-x: hidden;
    overflow-y: visible;
}

.tab-custom > div {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.tab-custom .ui.text.menu {
    flex: 0;
    display: flex;
    font-family: Montserrat,serif;
    flex-wrap: wrap;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    width: 100%;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #dadfea;
    justify-content: center;
    flex-direction: row;
}

.dark tfoot.full-width th {
    background-color: #121212 !important;
}

.tab-custom a.item {
    color: #dadfea !important;
    margin-right: 24px !important;
}

.tab-custom a.active.item {
    color: #21B4CA !important;
    border-bottom: 2px solid #21B4CA !important;
}

.tab-custom .ui.text.menu .item:hover {
    color: #21B4CA !important;
}

.tab-custom::-webkit-scrollbar {
    width: 10px;
    background-color: #2c2c2c02;
    height: 8px;
}

.tab-custom::-webkit-scrollbar-thumb {
    background: #2c2c2c20;
}
