.time-input-dropdown {
  background-color: #323232;
  /*border: 1px solid #32323211;*/
  position: absolute;
  top: 36px;
  z-index: 100;
  left: 0;
  color: #c4c4c4;
  width: 220px;
  display: flex;
  flex-direction: column;
}

.timeInput-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeInput-container .ui.loader {
  left: 90%;
}

.timeInput-container {
  width: 100%;
  position: relative;
  display: flex;
  overflow: visible;
}

.timeInput-disabled {
  opacity: 0.4;
}

.input-modal .timeInput-container.custom-input.input-bg.error {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

.timeInput-container.custom-input.error {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

.custom-input {
  font-family: Montserrat, serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.time-input-dropdown:focus {
  border: none !important;
  outline: none;
}

.time-input-items {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.time-input-items:first-child {
  border-right: 0.5px solid #32323211;
}

.time-input-items:nth-child(2) {
  border-left: 0.5px solid #32323211;
}

.time-input-dropdown > div {
  width: 100%;
  display: flex;
  position: absolute;
}

.time-input-items:hover .time-input-bottom {
  display: flex;
}

.time-input-items:hover .time-input-top {
  display: flex;
}

.timeInput-container {
  position: relative;
}

span.time-input-icon {
  width: 50%;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  border-top: 1px solid #c4c4c444;
}

.time-input-icon:first-child {
  background-color: green;
}

.time-input-icon:nth-child(2) {
  background-color: darkred;
}

span.time-input-icon:hover {
  opacity: 0.8;
}

.time-input-bottom,
.time-input-top {
  z-index: 200;
  display: none;
  background-color: #32323277;
  flex: 1 1;
  color: white;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.4s;
}

.time-input-bottom:hover,
.time-input-top:hover {
  background-color: #323232;
}

.time-input-bottom {
  bottom: 0;
  margin-bottom: 0;
}

.time-input-top {
  top: 0;
  margin-bottom: 0;
}

span.time-input-item:hover {
  background: #979797;
}

.time-input-holder {
  display: block;
  position: absolute;
  top: calc(50% - 65px);
  left: 0;
  right: 0;
  width: 140px;
  height: 40px;
  transform: translateY(62%);
  background-color: #21B4CA44 !important;
}

span.time-input-item {
  height: 40px;
  text-align: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.time-input-control {
  border-top: 0.5px solid #32323211;
}

.time-input-down,
.time-input-down div {
  top: calc(100% + 2px);
  background-color: #323232;
}

.time-input-down .time-input-control {
  top: calc(100% + 202px);
}

.time-input-up div {
  bottom: calc(100% + 80px);
}
.time-input-up .time-input-control {
  bottom: calc(100% + 42px);
}
.time-input-items {
  background-color: #323232;
}

.time-input-up {
  bottom: calc(100% + 40px);
  background-color: #323232;
}

.time-input-down .time-input-holder {
  top: calc(58px);
  z-index: 99;
}

.time-input-up .time-input-holder {
  top: -224px;
  z-index: 99;
}
